<template>
  <div class="body">
    <div class="more-one">
      <img class="img1" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/%E5%9B%BE3.jpg" alt="">
      <img class="img2 animate__animated animate__fadeInDown" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/%E5%AD%973.png" alt="">
      <el-button @click="startFootFrom" class="linshibtn" type="primary">立即抢占海量客户</el-button>
    </div>
    <!-- <zhe></zhe> -->
    <!-- 底部弹起表单 -->
    <div class="from-foot " v-show="isFootFrom">
      <div class="from-foot-from animate__animated animate__fadeInUp">
        <h3 @click="closeFootFrom">×</h3>
        <el-form :model="fromData" class="part-foot-from" label-position="left" label-width="50px" ref="fromData"
          :rules=rules>
          <el-form-item label="" prop="user" class="from-child">
            <el-input v-model.trim="fromData.user" placeholder="姓名"></el-input>
          </el-form-item>
          <el-form-item label="" prop="phone" class="from-child">
            <el-input v-model.trim="fromData.phone" placeholder="手机"></el-input>
          </el-form-item>
          <el-form-item label="" prop="name" class="from-child">
            <el-input v-model.trim="fromData.name" placeholder="公司名称"></el-input>
          </el-form-item>
          <el-form-item label="" prop="business" class="from-child">
          <el-input v-model.trim="fromData.business" placeholder="行业分类（不清楚可不填）"></el-input>
        </el-form-item>
          <el-button class="linshibtn" @click="submitFromData('fromData')" type="primary">立即咨询</el-button>
        </el-form>
      </div>
    </div>
    <!-- 第二部分 -->
    <div class="more-er">
      <div class="more-one-top">
        <p class="more-one-top-p1">OPPO营销（浙江新聚互动）</p>
        <p class="more-one-top-p2">浙江新聚互动网络科技有限公司荣誉墙</p>
      </div>
      <div class="more-one-bottom">
        <ul class="more-one-tab">
          <li class="more-one-cont" :class="ismoreone==0?'more-active':''" @click="ismoreone=0">公司荣誉</li>
          <li class="more-one-cont" :class="ismoreone==1?'more-active':''" @click="ismoreone=1">公司环境</li>
          <li class="more-one-cont" :class="ismoreone==2?'more-active':''" @click="ismoreone=2">公司团建</li>
        </ul>
        <div class="more-one-tab2" v-show="ismoreone==0">
        <ul>
          <li class="tab2-li">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E8%8D%A3%E8%AA%891.jpg" alt="">
          </li>
          <li class="tab2-li">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E8%8D%A3%E8%AA%892.jpg" alt="">
          </li>
          <li class="tab2-li">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E8%8D%A3%E8%AA%893.jpg" alt="">
          </li>
          <li class="tab2-li">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E8%8D%A3%E8%AA%894.jpg" alt="">
          </li>
          <li class="tab2-li">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E8%8D%A3%E8%AA%895.jpg" alt="">
          </li>
          <li class="tab2-li">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E8%8D%A3%E8%AA%896.jpg" alt="">
          </li>
          <li class="tab2-li">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E8%8D%A3%E8%AA%897.jpg" alt="">
          </li>
          <li class="tab2-li">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E8%8D%A3%E8%AA%898.jpg" alt="">
          </li>
          <li class="tab2-li">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E8%8D%A3%E8%AA%899.jpg" alt="">
          </li>
          <li class="tab2-li">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E8%8D%A3%E8%AA%8910.jpg" alt="">
          </li>
          <!-- <li class="tab2-li">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E8%8D%A3%E8%AA%8912.jpg" alt="">
          </li> -->
        </ul>
      </div>
      <div class="more-one-tab3" v-show="ismoreone==1">
        <ul>
          <li class="tab2-li2">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E5%85%AC%E5%8F%B8%E7%8E%AF%E5%A2%835.jpg" alt="">
          </li>
          <li class="tab2-li2">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E5%85%AC%E5%8F%B8%E7%8E%AF%E5%A2%836.jpg" alt="">
          </li>
          <li class="tab2-li2">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E5%85%AC%E5%8F%B8%E7%8E%AF%E5%A2%833.jpg" alt="">
          </li>
          <li class="tab2-li2">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E5%85%AC%E5%8F%B8%E7%8E%AF%E5%A2%832.jpg" alt="">
          </li>
          <li class="tab2-li2">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E5%85%AC%E5%8F%B8%E7%8E%AF%E5%A2%831.jpg" alt="">
          </li>
        
        </ul>
      </div>
      <div class="more-one-tab4" v-show="ismoreone==2">
        <ul>
          <li class="tab2-li3">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E5%9B%A2%E5%BB%BA7.jpg" alt="">
          </li>
          <li class="tab2-li3">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E5%9B%A2%E5%BB%BA2.jpg" alt="">
          </li>
          <li class="tab2-li3">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E5%9B%A2%E5%BB%BA3.jpeg" alt="">
          </li>
          <li class="tab2-li3">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E5%9B%A2%E5%BB%BA4.jpeg" alt="">
          </li>
          <li class="tab2-li3">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/xjhdgw/zjxjhdgw/img/%E5%9B%A2%E5%BB%BA6.jpg" alt="">
          </li>
         
        </ul>
      </div>
      </div>
   
      </div>
  <!-- 第三部分 -->
      <div class="more-two">
      <div class="more-two-top">
        <p class="more-two-top-p1">与传统方式相比OPPO营销有什么不同</p>
        <p class="more-two-top-p2">OPPO营销代表互联网时代的需求营销，与报纸、电视等传统推广方式的根本区别在于</p>
      </div>
      <div class="more-two-bottom">
        <ul class="more-two-tab">
          <li class="more-two-cont" :class="ismoretwo==0?'more-active':''" @click="ismoretwo=0"> #抢占客户# </li>
          <li class="more-two-cont" :class="ismoretwo==1?'more-active':''" @click="ismoretwo=1"> #精准定位# </li>
          <li class="more-two-cont" :class="ismoretwo==2?'more-active':''" @click="ismoretwo=2"> #客户转化率高# </li>
        </ul>
      </div>
  </div>
      <!-- 表单提交后的提示信息 -->
      <div class="subSuccess " v-show="isSuss">
      <div class="message animate__animated animate__fadeInDown">
        <h2 @click="changeIsSuss">×</h2>
        <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/icon-success.png" alt="">
        <div class="mss-text">
          <h3 class="mess-text-p">信息提交已成功</h3>
          <p>您的专属顾问会在一个工作日内联系您，请您保持电话畅通</p>
          <p>信息提交已成功</p>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { submitData } from '@/api/http'
export default {
  // components: { zhe },
  data() {
    return {
      isSuss:false,
      isFootFrom: false,
      ismoreone:0,
      ismoretwo:0,
      fromData: {
        user: '',
        phone: '',
        name: '',
        business:""
      },
      rules: {
        user: [
          { required: true, message: "请输入您的姓名", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入您的联系方式", trigger: "blur" },
          { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: "必须输入正确的电话号码", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入您的公司名称", trigger: "blur" }
        ]
      },
    }
  },
  methods: {
    changeIsSuss(){
      this.isSuss=!this.isSuss
    },
    closeFootFrom() {
      this.isFootFrom = !this.isFootFrom
    },
    startFootFrom() {
      this.isFootFrom = !this.isFootFrom
    },
    submitFromData(fromData) {
      this.$refs[fromData].validate(async (valid) => {
        if (valid) {
          // 当数据有效时
          console.log(this.fromData);
          fromData=this.fromData
          let res = submitData(fromData).then(res => {
            // console.log('res',res.data);
          })
           // 弹起消息提示框
           this.isSuss=true
            // 清空表单数据
            this.$refs.fromData.resetFields()
            // 关闭表单
            this.isFootFrom=false
        }
        else{
            this.$message.error('提交失败')
        }
      })
    },
  },
}
</script >

<style lang="less">
.body {
  width: 100vw;
  margin: 0 auto;

  .more-one {
    position: relative;
    .linshibtn {
      position: absolute;
      left: 30%;
      top: 70%;

    }

    width: 100vw;
    height: 100vw;
    margin-top: 10vw;

    .img1 {
      width: 100%;
      height: 100%;
    }
    .img2{
      width: 92vw;
    height: 24vw;
    position: absolute;
    top: 13vw;
    left: 4vw
    }
    .more-one-top-p1 {
      color: #000;
      font-size: 18px;
      font-weight: 600;
    }

    .more-one-top-p2 {
      margin-top: 2vw;
      color: #919191;
      font-size: 13px;
    }
  }

}
.more-er{
  height: 330vw;
  .more-one-bottom{
    width: 100vw;

    margin-top: 5vw;
    .more-one-tab{
      width: 90vw;
      height: 10vw;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      list-style: none;
      .more-one-cont{
        width: 25%;
        height: 100%;
        border: 1px solid #ccc;
        line-height: 10vw;
      }
      .more-active{
        background-color:#007bff;
        color: #fff;
        transition: all .4s linear;
        border: 1px solid #007bff;
      }
    }
    .more-one-tab2{
      width: 100vw;
      position: relative;
     ul{
      margin-top: 5vw;
      list-style: none;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .tab2-li{
        img{
          width: 45vw;
          height: 55vw;
        }
      }
     }
    }
     .more-one-tab3{
      width: 100vw;
      position: relative;
     ul{
      margin-top: 5vw;
      list-style: none;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .tab2-li2{
        img{
          width: 80vw;
          height: 60vw;
        }
      }
     }
    }
    .more-one-tab4{
      width: 100vw;
      height: 200vw;
      position: relative;
     ul{
      margin-top: 5vw;
      list-style: none;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .tab2-li3{
        img{
          width: 80vw;
          height: 60vw;
        }
      }
     }
    }

  }
}
// 第三部分
.more-two {
    position: relative;
    top: 10vw;
    .linshibtn {
      position: absolute;
      left: 30%;
      top: 70%;

    }

    width: 100vw;
    height: 100vw;
    margin-top: 10vw;

    .img1 {
      width: 100%;
      height: 100%;
    }
    .img2{
      width: 92vw;
    height: 24vw;
    position: absolute;
    top: 13vw;
    left: 4vw
    }
    .more-two-top-p1 {
      color: #000;
      font-size: 18px;
      font-weight: 600;
    }

    .more-two-top-p2 {
      margin-top: 2vw;
      color: #919191;
      font-size: 13px;
    }
  }
  .more-two-bottom{
    width: 100vw;
    height: 100vw;
    margin-top: 5vw;
    .more-two-tab{
      width: 100vw;
      height: 10vw;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      list-style: none;
      .more-two-cont{
        width: 35%;
        height: 100%;
        border: 1px solid #ccc;
        line-height: 10vw;
      }
      .more-active{
        background-color:#007bff;
        color: #fff;
        transition: all .4s linear;
        border: 1px solid #007bff;
      }
    }
  }

.from-foot {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  .from-foot-from {
    // text-align: right;
    h3 {
      text-align: right;
    }

    width: 100vw;
    height: 100vw;
    background-color: #fff;
    position: fixed;
    bottom: 0;

    .part-foot-from {
      margin-top: 10vw;

      .from-child {
        width: 80vw;
      }
    }

  }
}
// 表单提交成功后的提示信息
.subSuccess{
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  .message{
    width: 90vw;
    height: 70vw;
    background-color: #fff;
    margin: 20vh auto;
    border-radius: 2vw;
    h2{
      margin-left: 80vw;
      color:#6d6c6c;
    }
    img{
      width: 52px;
      height: 52px;
    }
    .mss-text{
      width: 55vw;
      height: 40vw;
      margin: 5vw auto;
      .mess-text-p{
          margin-bottom: 5vw;
      }
    }
  }
}
</style>